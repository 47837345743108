import { flashMessage } from '~/utils/flash'
import { jsonRequest } from '~/utils/request'

export default (path, params, i18n) => ({
  originalQuantity: params.amount,

  addToCart(after) {
    jsonRequest(path, 'POST', params).then((data) => {
      if (!parseInt(params.amount)) return this._promptForQuantity(after)

      this.handleResponse(data)
      if (typeof after === 'function') after()
    })
  },

  handleResponse({ type, message, data }) {
    flashMessage(message, type)

    if (data.number_of_jobs != null) this.numberOfJobs = data.number_of_jobs
    if (data.problematic_extra_ids != null)
      this.problematicOptions = data.problematic_extra_ids
    if (data.problematic_option_groups != null)
      this._highlightProblematicOptionGroups(data.problematic_option_groups)

    params.amount = this.originalQuantity
  },

  noQuantity() {
    return !/\d/.test(this.quantity)
  },

  _promptForQuantity(after) {
    this._openModal(after)
    setTimeout(this._prefillInputs, 10)
  },

  _highlightProblematicOptionGroups(groups) {
    groups.forEach((group) => {
      const element = document.querySelector(`[data-option-group='${group}']`)
      if (element) element.classList.add('with-error')
    })
  },

  _openModal(after) {
    this.$store.modal.inline(i18n.modalTitle, '#quantity_modal_template', {
      cancel: true,
      confirm: () => {
        const [input, description] = [
          getInput('input'),
          getInput('description'),
        ]

        if (!input) return

        params.amount = input.value
        params.description = description?.value
        this.addToCart(after)
      },
      confirmLabel: i18n.confirmLabel,
    })
  },

  _prefillInputs() {
    const description = getInput('description')

    if (description) description.value = params.description || ''
  },
})

const getInput = (name) => document.getElementById(`quantity_modal_${name}`)
