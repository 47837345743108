import { flashSuccess } from '~/utils/flash'
import { jsonRequest } from '~/utils/request'

export default (path, active) => ({
  active,

  toggle() {
    jsonRequest(path, 'PUT').then(({ message }) => {
      flashSuccess(message)
      this.active = !this.active
    })
  },
})
