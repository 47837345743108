import { flashFailure } from '~/utils/flash'
import { jsonRequest } from '~/utils/request'

export default (path, jobId) => ({
  copy: false,

  openPreditor() {
    const data = { copy_design: this.copy ? '1' : '0', job_id: jobId }
    jsonRequest(path, 'POST', data).then(({ redirect_to, message }) => {
      if (redirect_to) location.href = redirect_to
      else flashFailure(message || 'oeps, er ging iets fout!')
    })
  },
})
