/**
 * Parses raw HTML string into a DOM object
 *
 * @param {string} rawHtml
 * @returns {HTMLElement} The parsed document
 */
export const parseRawHtmlDoc = (rawHtml) =>
  new DOMParser().parseFromString(rawHtml, 'text/html').body

/**
 * Parses raw HTML string into a DOM object and selects a part of it
 *
 * @param {string} rawHtml
 * @param {string} selector
 * @returns {HTMLElement} The selected element
 */
export const parseRawHtmlPart = (rawHtml, selector) =>
  parseRawHtmlDoc(rawHtml).querySelector(selector)

/**
 * Parser raw HTML string, selects the targeted element and replaces it
 *
 * @param {string} rawHtml
 * @param {string} selector
 * @returns {HTMLElement} The new element
 */
export const parseAndReplace = (rawHtml, selector) => {
  const replacement = parseRawHtmlPart(rawHtml, selector)
  document.querySelector(selector)?.replaceWith(replacement)
  return replacement
}
