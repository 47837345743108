import './modal_component.scss'

import { request } from '~/utils/request'

export default () => ({
  _placeholder: null,
  error: false,
  loading: false,
  size: 'default',
  height: 'default',

  init() {
    this.$watch('$store.modal.content', (newContent, oldContent) => {
      this.error = false
      this.toggleModal(!!newContent)
      if (newContent) this.loadContent(newContent)
      if (!newContent) this.restoreContent(oldContent)
    })
    this.$watch('$store.modal.options', (options) => {
      this.size = options.size || 'default'
      this.height = options.height || 'default'
      this.setLabels(options)
    })
  },

  toggleModal(isOpen) {
    isOpen ? this.$el.showModal() : this.$el.close()
  },

  loadContent(content) {
    content.type == 'text'
      ? this.renderText(content)
      : content.type == 'url'
        ? this.renderUrl(content)
        : content.type == 'selector'
          ? this.renderSelector(content)
          : ''
  },

  setLabels(options) {
    this.$refs.cancel.innerHTML =
      options.cancelLabel || this.$refs.cancel.dataset.label
    this.$refs.confirm.innerHTML =
      options.confirmLabel || this.$refs.confirm.dataset.label
  },

  renderText(content) {
    this.$refs.content.innerHTML = content.text
    this.focusFirstInput()
  },

  renderUrl({ url, method, values }) {
    this.loading = true

    request(url, method, values)
      .then((response) => response.text())
      .then((html) => {
        this.$refs.content.innerHTML = html
        this.focusFirstInput()
      })
      .then(() => (this.loading = false))
  },

  renderSelector(content) {
    let element = document.querySelector(content.selector)
    if (element.nodeName.toLowerCase() == 'template') {
      element = element.content.cloneNode(true)
    } else {
      this._placeholder = document.createElement('div')
      element.replaceWith(this._placeholder)
    }

    this.$refs.content.innerHTML = ''
    this.$refs.content.append(element)
    this.focusFirstInput()
  },

  restoreContent() {
    if (this._placeholder) {
      this._placeholder.replaceWith(this.$refs.content.firstElementChild)
      this._placeholder = null
    }
    this.$refs.content.innerHTML = ''
  },

  focusFirstInput() {
    this.$refs.content.querySelector('input')?.focus()
  },
})
