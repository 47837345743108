import './active_search_component.scss'

export default (query) => ({
  query,

  search(event) {
    if (event.keyCode == 40) return

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this.$el.form.requestSubmit(), 300)
  },

  selectFirstResult() {
    this.$refs.results.querySelector('li:first-child a').focus()
  },

  selectNextSibling() {
    const nextSibling = this.$el.closest('li').nextElementSibling
    if (nextSibling) nextSibling.querySelector('a').focus()
  },

  selectPreviousSibling() {
    const previousSibling = this.$el.closest('li').previousElementSibling
    if (previousSibling) previousSibling.querySelector('a').focus()
    else this.$refs.input.focus()
  },
})
