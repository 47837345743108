import { getCSRFToken } from '~/utils/security'
import { flashInfo, flashFailure } from '~/utils/flash'
import { parseRawHtmlPart } from '~/utils/dom-parser'

export default (path, satisfied) => ({
  xhr: null,
  progress: 0,
  touched: false,
  busy: false,
  started: null,
  satisfied,

  init() {
    this.$refs.input && (this.$refs.input.value = '')
    this.progress = 0
    this.started = null
    this.xhr = new XMLHttpRequest()
  },

  busy() {
    return this.progress > 0 && this.progress < 100
  },

  start() {
    if (this.validate()) this.upload()
  },

  validate() {
    if (this.$refs.input.files.length == 0) {
      flashFailure('Please select at least one file')
      return false
    }

    return true
  },

  upload() {
    this.xhr.open('POST', path, true)
    this.xhr.setRequestHeader('X-CSRF-Token', getCSRFToken())
    this.xhr.upload.onprogress = this._updateProgress.bind(this)
    this.xhr.onload = this._finalize.bind(this)
    this.xhr.send(gather(this.$refs.input.files))
    this.started = new Date().getTime()
  },

  cancel() {
    this.xhr.abort()
    this.init()
    flashInfo('Job upload canceled')
  },

  _updateProgress(e) {
    if (e.lengthComputable) this.progress = (e.loaded / e.total) * 90
    if (this.progress < 90) return

    const step = (new Date().getTime() - this.started) / 1500
    this._fakeProgress(10 / step)
  },

  _fakeProgress(step) {
    if (this.progress >= 100) return

    this.progress = Math.min(this.progress + step, 100)
    setTimeout(() => this._fakeProgress(step), 1000)
  },

  _finalize() {
    if (this.xhr.status === 200) {
      const node = parseRawHtmlPart(this.xhr.responseText, ':first-child')
      this.$root.parentNode.replaceWith(node)
    } else flashFailure(`Upload failed. Status: ${this.xhr.status}`)
  },
})

function gather(files) {
  const formData = new FormData()
  for (let i = 0; i < files.length; i++) formData.append('files[]', files[i])
  return formData
}
