// Load CSS entrypoint
import '~/application.css'

// Honeybadger
import Honeybadger from '@honeybadger-io/js'
Honeybadger.configure({
  apiKey: document.querySelector('meta[name=honeybadger-key]').content,
  environment: document.querySelector('meta[name=honeybadger-env]').content,
})

// Turbo
import * as Turbo from '@hotwired/turbo'

// Set up Alpine
import Alpine from 'alpinejs'
window.Alpine = Alpine

// Add alpine
import 'alpine-turbo-drive-adapter'
import collapse from '@alpinejs/collapse'
import anchor from '@alpinejs/anchor'
import focus from '@alpinejs/focus'
import persist from '@alpinejs/persist'
import { registerAlpineExtensions } from '~/utils/alpine-extensions'

// Alpine components
registerAlpineExtensions(
  'data',
  import.meta.glob('~/**/components/*.js', { eager: true }),
)
registerAlpineExtensions(
  'data',
  import.meta.glob('~/../components/**/*_component.js', { eager: true }),
  /components\/(.+)_component\.js$/,
)

// Alpine directives
registerAlpineExtensions(
  'directive',
  import.meta.glob('~/**/directives/*.js', { eager: true }),
)

// Alpine magics
registerAlpineExtensions(
  'magic',
  import.meta.glob('~/**/magics/*.js', { eager: true }),
)

// Alpine stores
registerAlpineExtensions(
  'store',
  import.meta.glob('~/**/stores/*.js', { eager: true }),
)

// Alpine plugins
import { getCSRFToken } from '~/utils/security.js'
Alpine.plugin(collapse)
Alpine.plugin(focus)
Alpine.plugin(anchor)
Alpine.plugin(persist)

// Start Alpine
Alpine.start()

// GSAP import
import { gsap } from 'gsap'

// Import GSAP plugins:
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

// Register GSAP plugins
gsap.registerPlugin(ScrollToPlugin)
