/**
 * Available request methods
 */
export const Method = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
}

/**
 * Gets the CSRF token from the meta tag
 *
 * @returns The CSRF token, if found
 */
export function getCSRFToken() {
  const meta = document.querySelector('meta[name=csrf-token]')
  return meta.content
}

/**
 * Performs a request
 *
 * @param url - The request url
 * @param [method=Method.POST] - The request http method
 * @param [data] - POST body parameters object
 * @returns A promise
 */
export async function request(url, method = Method.POST, data = {}) {
  const options = { method, headers: { 'X-CSRF-Token': getCSRFToken() } }
  if (method == Method.GET) url = urlWithQueryParams(url, data)
  else options.body = formData(data)
  return fetch(url, options).catch((error) => console.error('Error:', error))
}

/**
 * Performs a JSON request
 *
 * @param url - The request url
 * @param [method=Method.POST] - The request http method
 * @param [body] - POST body parameters object
 * @returns A promise
 */
export async function jsonRequest(url, method = Method.POST, body) {
  const options = {
    method,
    headers: {
      'X-CSRF-Token': getCSRFToken(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }
  return fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.error('Error:', error))
}

/**
 * Builds a url for the target path with a query param string
 *
 * @param pathname - The path name
 * @param params - Object with query params
 * @returns The complete url
 */
const urlWithQueryParams = (pathname, params) => {
  const url = new URL(location.href)
  url.pathname = pathname
  const searchParams = url.searchParams
  for (const [key, value] of Object.entries(params))
    searchParams.set(key, value)
  return url.toString()
}

/**
 * Builds a form data object
 *
 * @param data - The form data as an object
 * @retuns A form data object
 */
const formData = (data) => {
  const body = new FormData()
  Object.entries(data).forEach(([key, value]) => body.append(key, value))
  return body
}
