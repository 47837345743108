import { request } from '~/utils/request'
import { parseAndReplace } from '~/utils/dom-parser'

export default (path, prefix) => ({
  query: '',

  init() {
    this.$refs.search.focus()
  },

  load(group) {
    request(path, 'GET', { scope: prefix, group, query: this.query })
      .then((response) => response.text())
      .then((rawHtml) => parseAndReplace(rawHtml, '#relations'))
  },
})
