import './browser_component.css'
import { request } from '~/utils/request'
import { parseAndReplace } from '~/utils/dom-parser'

export default (path, selector) => ({
  chooseJob(sourceJobId) {
    this.updateJob(sourceJobId)
    this.$dispatch('modal:confirm')
  },

  updateJob(sourceJobId) {
    request(path, 'POST', { source_job_id: sourceJobId })
      .then((response) => response.text())
      .then((html) => parseAndReplace(html, selector))
      .catch((error) => console.error(error))
  },
})
